<template>
  <div class="h-dvh flex flex-col">
    <div class="border-b border-b-gy2 py-3 px-6 bg-blueLight0">
      <div class="w-full flex justify-between items-center h-[32px]">
        <div class="flex justify-center items-center text-white font-bold italic text-xl select-none bg-it-service-logo bg-contain bg-no-repeat w-[121px] h-[24px] cursor-pointer" @click="handleClickLogo"></div>
        <div v-if="!skeletonLoading && !errorType && user" class="flex justify-center items-center space-x-2">
          <t-dropdown trigger="hover" :options="avatorDropdownOptions" @click="handleAvatorClick">
            <t-button theme="default" variant="text">
              {{ user.chnName }}
              <template #icon><t-icon name="user-circle" size="20" /></template>
            </t-button>
          </t-dropdown>
        </div>
        <div v-else class="text-primary cursor-pointer select-none" @click="logout">
          登出
        </div>
      </div>
    </div>
    <div v-if="skeletonLoading || errorType || !user" class="w-full lg:min-w-[480px] min-h-[480px] flex-1">
      <div class="flex flex-col h-full" style="background: linear-gradient(rgb(217, 232, 255) 0%, rgba(242, 247, 255, 0.8) 81.06%, rgba(242, 247, 255, 0.5) 99.88%);">
      <t-loading class="h-full" v-if="skeletonLoading" :loading="skeletonLoading" />
      <div v-else-if="errorType" class="h-full w-full flex justify-center content-center flex-wrap font-medium">
          {{ errorType }}
      </div>
      <div v-loading="isLoadingCurrentProj" v-else-if="!user" class="h-full w-full flex justify-center items-center">
          <div class="relative font-medium flex flex-col gap-6">
          <div class="absolute top-[-48px] left-0">请选择您要访问的项目：</div>
          <div v-for="proj in projList" class="w-[600px] h-[56px] bg-white flex justify-between items-center rounded-lg px-6 py-1 cursor-pointer" @click="handleChooseProj(proj)">
              <span>{{ proj.projChnName }}</span>
              <span class="font-normal text-primary">前往></span>
          </div>
          </div>
      </div>
      </div>
    </div>
    <RouterView v-else/>
  </div>
</template>
<script setup lang="ts">
import { RouterView, useRouter } from 'vue-router'
import { ref, onBeforeMount } from 'vue'
import { useUserStore, type User } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { sendMessages } from './TUIKit/components/TUIChat/utils/sendMessage';
import { useChatStore } from './stores/chat';
import { watch } from 'vue';
import TUIChatEngine from '@tencentcloud/chat-uikit-engine';

const { shouldShine, currentConversation } = storeToRefs(useChatStore());

const router = useRouter();
const startInterval = () => {
  setInterval(function() {
    if (shouldShine.value === true) {
      if (/新/.test(document.title) == false) {
        document.title = '您有新消息';    
      } else {
        document.title = 'IT Service';
      }
    } else {
      document.title = 'IT Service';
    }
  }, 500);
  
  window.onfocus = function() {
    shouldShine.value = false;
  };
}
startInterval();

const userStore = useUserStore();
const { user, projList } = storeToRefs(userStore);
const { login, logout, setCurrentProj } = userStore;

const skeletonLoading = ref(true);
const errorType = ref<'您没有本站点访问权限' | '网络错误请稍后再试' | '您没有此项目访问权限' | undefined>();

onBeforeMount(async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('code');
  try {
    await login(code);
    if (projList.value.length === 0) errorType.value = '您没有本站点访问权限';
    if (projList.value.length === 1) handleChooseProj(projList.value[0]);
  } catch (error) {
    console.log('onBeforeMount error', error);
    errorType.value = '网络错误请稍后再试'
  } finally {
    skeletonLoading.value = false;
  }
});

const avatorDropdownOptions = [
  { content: '登出', value: 0 },
  // { content: '发送满意度评价(测试用)', value: 1 }
]
const handleAvatorClick = async (data: { content: string, value: number }) => {
  if (data.value === 0) {
    await logout();
  } else if (data.value === 1) {
    sendMessages([{
      type: 'TIMCustomElem',
      payload: {
        // @ts-ignore
        data: JSON.stringify({
          business_id: 'yst_rate',
          notice_type: 'pre_settle',
          ticket_id: 401936,
          proj_id: 4,
          prod_id: 5
        })
      }
    }], currentConversation.value)
  }
}
const handleClickLogo = () => {
  if (router.currentRoute.value.name === 'Chat' || router.currentRoute.value.path === '/') return;
  router.push({
    path: '/'
  });
}

// 选择项目逻辑
const isLoadingCurrentProj = ref(false);
const handleChooseProj = (proj: User) => {
  isLoadingCurrentProj.value = true;
  router.push({
    path: `/${proj.projEngName}/search`,
  })
  isLoadingCurrentProj.value = false;
};
watch(() => ({ path: router.currentRoute.value.path, projList }), (val, oldVal) => {
  if (val.path !== '/') {
    const projEngName = val.path.split('/')[1];
    const targetProj = val.projList.value.find(p => p.projEngName === projEngName);
    if (!targetProj && val.projList.value && val.projList.value.length > 0) errorType.value = '您没有此项目访问权限';
    if (projEngName && targetProj) {
      setCurrentProj(targetProj);
    }
  } else {
    if (TUIChatEngine.isReady()) TUIChatEngine.logout();
    if (errorType.value === '您没有此项目访问权限') errorType.value = undefined;
    setCurrentProj(null);
  }
}, {
  deep: true,
  // immediate: true,
});
</script>
