<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div :class="['flex justify-center mt-[36px]']">
    <div class="w-[800px]">
      <div :class="['mb-[24px] flex translate-y-0.5 w-[800px] items-end']">
        <div :class="['bg-contain h-[56px] w-[112px] mr-[12px]', loading ? 'bg-search-icon-loading' : 'bg-search-title-icon']"></div>
        <div class="text-[#00000066] text-[14px] leading-[22px] flex">
          以下为“<div class="overflow-hidden text-ellipsis whitespace-nowrap max-w-[500px]">{{route.query.keyword}}</div>”相关结果</div>
      </div>
      <SearchInput :hasResult="true" class="mb-[24px]" :defaultKeyword="keyword" @search="handleSearch"/>
      <ColorfulSearchResult ref="colorfulSearchResultRef" style="margin-bottom: 24px;"></ColorfulSearchResult>
      <div class="w-[800px] bg-white rounded-[8px] px-[30px] py-[20px] mb-[48px]" v-loading="loading">
        <template v-if="resultList && resultList.length">
          <div v-for="(item,index) in resultList" :key="index" class="border-b-[1px] border-[#E3E6EB]">
           <searchResultItem :keyword="keyword.toString()" :article="item"/>
          </div>
          <div style="width: 100%;text-align: center;min-height: 20px;padding-top:10px;">
            <div v-if="listStatus==='all-loaded'" style="color: var(--td-font-gray-3)">到底啦~ 没有更多数据了</div>
            <t-loading v-else text="加载中..." size="small" />
          </div>
        <!-- <t-pagination v-model="current" :totalContent='false' :total="total" @page-size-change="PageSizeChange"
        v-model:page-size="pageSize" class="pt-[26px] pb-[6px]" @current-change="KnowledgeSearch" /> -->
        </template>
        <div v-else class="text-fontgy3 text-center h-[96px] leading-[96px]">{{ loading ? "" : '暂无搜索结果' }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SearchInput from '@/components/SearchInput.vue';
import { onMounted, onUnmounted,computed,watch, ref } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { knowledgeSearch } from '@/network/requests';
import ColorfulSearchResult from '@/components/colorfulSearchResult/index.vue';
import searchResultItem from './searchResultItem.vue';
import { useUserStore } from '@/stores/user';
import { reportData } from '@/utils/utils'
// import type { articleInfo } from './type';
import * as _ from 'lodash';

const colorfulSearchResultRef = ref();

const router = useRouter();
const route = useRoute();
const userStore = useUserStore();

const current = ref(0);
const loading = ref(false)
const isearch_id = ref('')
const hasChangeKeyword=ref(true)
const resultList = ref<any[]>([])

const listStatus = ref<string>('loaded');
const pageWrapHtml = ref<HTMLDivElement | null>(null);

const pageSize = computed(() => {
  return Math.ceil(window.innerHeight/120);
});

watch(() => route.query.keyword, async (val:string) => {
  if (val) {
    isearch_id.value = '';
    resultList.value = [];
    loading.value = true;
    current.value = 0;
    hasChangeKeyword.value = true
    await Promise.all([KnowledgeSearch(), colorfulSearchResultRef.value.init({ question: route.query.keyword })])
    setTimeout(() => {
      loading.value = false;
    }, 1000)
  }else { 
    router.push(`/search/home`)
  }
});

const event_type = computed(() => (route.query&&route.query.from)?`search_${route.query.from}_search` :'search_result_search');
const keyword = computed(() => { return (route.query.keyword as string) || ''} );

const KnowledgeSearch = async () => {
  if (!route.query.keyword || typeof(route.query.keyword) !== 'string') return;
  try {
    const resp = await knowledgeSearch({
      projId: String(userStore.user.projId),
      query: route.query.keyword,
      isearchId: isearch_id.value,
      offset: current.value,
      limit: pageSize.value
    })
    if (resp.data) { 
      resultList.value = [...resultList.value, ...resp.data.contents];
      // @ts-ignore
      isearch_id.value = resp.data.isearch_id;
      // 上报iwiki搜索结果 翻页不上报
      if (hasChangeKeyword.value) { 
        reportData([
          { key: 'event_type', value: `${event_type.value}_iwiki` },
          { key: 'event_content', value: `搜索${event_type.value.includes('home') ? '首页' : '结果页'}-进行搜索(iwiki)` },
          { key: 'question', value: keyword.value },
          { key: 'answer', value: JSON.stringify(resultList.value)},
        ])
        hasChangeKeyword.value=false
      }
    }
    // @ts-ignore
    listStatus.value = resp.data.next_offset && resp.data.next_offset === -1 ? 'all-loaded' : 'loaded';
    // @ts-ignore
    current.value = resp.data.next_offset;
    if (listStatus.value !== 'all-loaded' && resultList.value.length < pageSize.value) {
      listStatus.value = 'loading';
      return KnowledgeSearch()
    } else {
      return true;
    }
  } catch (error) {
    console.log('KnowledgeSearch Error:', error);
    loading.value = false;
  }
}

const handleSearch = (keyword) => {
  router.push({ name: 'searchResult', query: { keyword } })
}

// const PageSizeChange = () => { 
//   current.value = 1;
//   isearch_id.value = ''
//   KnowledgeSearch()
// }

const scollLoading = async () => {
  if (!pageWrapHtml.value||listStatus.value==='all-loaded'||listStatus.value==='loading') return;
  const scrollTop = pageWrapHtml.value.scrollTop;
  const scrollHeight = pageWrapHtml.value.scrollHeight;
  const clientHeight = pageWrapHtml.value.clientHeight;
  if (scrollTop + clientHeight >= scrollHeight - 5) { 
    listStatus.value='loading';
    await KnowledgeSearch()
    loading.value = false;
  }
};

onMounted(async () => { 
  if (route.query && route.query.keyword) {
    loading.value = true;
    // KnowledgeSearch()
    // await colorfulSearchResultRef.value.init({ question: route.query.keyword });
    await Promise.all([KnowledgeSearch(), colorfulSearchResultRef.value.init({ question: route.query.keyword })])
    loading.value = false;
    pageWrapHtml.value = document.querySelector('.scroll-box');
    if (pageWrapHtml.value) pageWrapHtml.value.addEventListener('scroll', _.debounce(scollLoading, 200));
  } else { 
    router.push(`/search/home`)
  }
})

onUnmounted(() => {
  if(pageWrapHtml.value) pageWrapHtml.value.removeEventListener('scroll', scollLoading);
});

</script>